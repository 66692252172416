import React from "react";
import ContactHeader from "components/Contact-header/contact-header";
import ContactForm from "components/Contact-form/contact-form";

const Contact = () => {

  return (
    <>

      <ContactHeader />
      <div className="main-content">
        <ContactForm />

        {/*   <Footer hideBGCOLOR /> */}
      </div>
    </>
  );
};

export const Head = () => {
  return (
    <>
      <title>Contact</title>
    </>
  )
}

export default Contact;
