import React from "react";
import Split from "../Split";
import AboutInfo1Data from "data/sections/about-info1.json";
import AboutInfo1DataDe from "data/sections/about-info1.de.json";

import { useLocal } from "utils/useLocalContent";

const AboutIntro = () => {

  const content = useLocal()[0] === "de" ? AboutInfo1DataDe : AboutInfo1Data;

  return (
    <section className="intro-sections pb-0">
      <div className="container" style={{ paddingTop: "90px" }}>
        <div className="row" style={{ paddingTop: "50px" }}>
          <div className="col-lg-3 col-md-4">
            <div className="htit sm-mb30" >
              <h4>{content.title}</h4>
            </div>
          </div>
          <div className="col-lg-8 offset-lg-1 col-md-8">
            <div className="text">
              <Split>
                <p
                  className="wow txt mb-10 words chars splitting"
                  data-splitting
                >
                  {content.paragraph1}
                </p>
                {/*  <p className="wow txt words chars splitting" data-splitting>
                  {AboutInfo1Data.paragraph2}
                </p> */}
              </Split>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutIntro;
