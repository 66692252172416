import React from "react";
import { Link } from "gatsby";
import appData from "data/app.json";
import { useLocal } from "utils/useLocalContent";

import initFullNavbarMenu from "common/initFullNavbarMenu";
import LangButton from "layouts/langButton";
import { Instagram } from 'lucide-react'


const links = [
  {
    name: "Home",
    link: "/"
  },
  {
    name: "About Us",
    link: "/about"
  },
  {
    name: "Service",
    link: "/services"
  },
  {
    name: "Contact",
    link: "/contact"
  },

];

const germanLinks = [
  {
    name: "Startseite",
    link: "/de/"
  },
  {
    name: "Über uns",
    link: "/de/about"
  },
  {
    name: "Dienstleistung",
    link: "/de/services"
  },
  {
    name: "Kontakt",
    link: "/de/contact"
  },

];

const NavbarFullMenu = ({ theme }) => {
  React.useEffect(() => {
    initFullNavbarMenu()
  }, []);

  const [lang] = useLocal();



  const currentLinks = lang === "de" ? germanLinks : links;

  return (
    <>
      <div id="navi" className={`topnav ${theme ? (theme === "light" ? "light" : "") : ""}`}>


        <div className="container-fluid" style={styles.menuCo}>
          <div className="logo">
            <Link to={lang === "de" ? "/de" : "/"}>
              {
                theme ? (
                  theme === "light" ? (
                    <img src={appData.darkLogo} alt="valvie" />
                  ) : (
                    <img src={appData.lightLogo} alt="valvie" />
                  )
                ) : (
                  <img src={appData.lightLogo} alt="valvie" />
                )
              }
            </Link>
          </div>

          <div style={styles.langButtonCo}>
            <LangButton />

            <div className="menu-icon" style={styles.menuButtonCo}>


              <span className="icon">
                <i></i>
                <i></i>
              </span>
              <span className="text" >
                <span className="menu-text word">Menu</span>
              </span>


            </div>
          </div>

        </div>
      </div>

      <div className="hamenu">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-8">
              <div className="menu-links">
                <ul className="main-menu">
                  {currentLinks.map((link, index) => (
                    <li key={index}>
                      <div className="o-hidden">
                        <Link to={link.link} className="link">
                          <span className="nm">{`0${index + 1}.`}</span>{link.name}
                        </Link>
                      </div>
                    </li>
                  ))}
                  {/* <li>
                    <div className="o-hidden">
                      <Link to="#" onClick={() => window.location.pathname = lang === "de" ? window.location.pathname.replace("/de", "") : `/de${window.location.pathname}`} className="link">
                        <span className="nm">{`0${currentLinks.length + 1}.`}</span>
                        {lang === "de" ? "EN" : "DE"}
                      </Link>
                    </div>
                  </li> */}
                </ul>

                <Link target="_blank" to="https://www.instagram.com/valvie.de?igsh=YWViM3RnZzRpYnZm" style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '1rem',
                  color: 'white',
                  fontSize: '1.5rem',
                  marginTop: '1rem'
                }}>      <p>{lang === 'de' ? 'Folgen Sie uns auf Instagram' : 'Follow us on Instagram '} </p>< Instagram /> </Link>

              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default NavbarFullMenu;


const styles = {
  menuCo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    //backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
  langButtonCo: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },
  menuButtonCo: {

  }
}