import React from 'react'
import { useLocal } from 'utils/useLocalContent'


const content = {
    title: 'Privacy Policy',
    content: [
        {
            name: 'Privacy Statement',
            text: `I am pleased about your visit to my website and your interest in my services. The protection of your personal data is important to me. Below I inform you about the processing of your data in connection with the visit to my website.`
        },
        {
            name: 'Responsible Party',
            text: `The responsible party within the meaning of the General Data Protection Regulation (GDPR) is:
            Valvie
            UG (limited liability) & Co. KG
            Harpprechtstr. 17
            80933 Munich`
        },
        {
            name: 'Collection and Processing of Personal Data',
            text: `I do not collect or process any personal data when you visit my website, unless you actively provide it to me via the contact form.`
        },
        {
            name: 'Contact Form',
            text: `If you use the contact form on my website to get in touch with me, the data you enter (e.g. name, email address, message) will be used exclusively to process your request. This data will only be stored for the period necessary to process your request. Your data will not be passed on to third parties.`
        },
        {
            name: 'Right to Lodge a Complaint with the Supervisory Authority',
            text: `If you believe that the processing of your personal data violates data protection law, you have the right to lodge a complaint with the competent supervisory authority.`
        }
    ]
}

const contentDe = {
    title: 'Datenschutz',
    content: [
        {
            name: 'Datenschutzerklärung',
            text: `Ich freue mich über Ihren Besuch auf meiner Website und Ihr Interesse an meinen Dienstleistungen. Der Schutz Ihrer persönlichen Daten ist mir ein wichtiges Anliegen. Nachfolgend informiere ich Sie über die Verarbeitung Ihrer Daten im Zusammenhang mit dem Besuch meiner Website.`
        },
        {
            name: 'Verantwortlicher',
            text: `Verantwortlicher im Sinne der Datenschutz-Grundverordnung (DSGVO) ist:
            Valvie
            UG (haftungsbeschränkt) & Co. KG
            Harpprechtstr. 17
            80933 München`
        },
        {
            name: 'Erfassung und Verarbeitung personenbezogener Daten',
            text: `Ich erhebe und verarbeite keine personenbezogenen Daten, wenn Sie meine Website besuchen, es sei denn, Sie geben mir diese aktiv über das Kontaktformular zur Verfügung.`
        },
        {
            name: 'Kontaktformular',
            text: `Wenn Sie das Kontaktformular auf meiner Website nutzen, um mit mir in Kontakt zu treten, werden die von Ihnen eingegebenen Daten (z. B. Name, E-Mail-Adresse, Nachricht) ausschließlich zur Bearbeitung Ihrer Anfrage verwendet. Eine Speicherung dieser Daten erfolgt nur für den Zeitraum, der zur Abwicklung Ihrer Anfrage erforderlich ist. Eine Weitergabe Ihrer Daten an Dritte findet nicht statt.`
        },
        {
            name: 'Beschwerderecht bei der Aufsichtsbehörde',
            text: `Wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer personenbezogenen Daten gegen Datenschutzrecht verstößt, haben Sie das Recht, eine Beschwerde bei der zuständigen Aufsichtsbehörde einzureichen.`
        }
    ]
}


const index = () => {
    return (

        <Body />
    )
}

export default index

export const Head = () => {
    const [lang] = useLocal()
    return (
        <>
            <title>{lang === 'de' ? contentDe.title : content.title}</title>
        </>
    )
}




const Body = () => {

    const [lang] = useLocal()

    const c = lang === 'de' ? contentDe : content
    return (
        <div style={styles.co}>

            <div style={styles.innerDiv}>
                <h1>{c.title}</h1>
                <div>
                    {c.content.map((item, index) => {
                        return (
                            <div key={index}>
                                <h2 style={styles.h2}>{item.name}</h2>
                                <p>{item.text}</p>
                            </div>
                        )
                    })}
                </div>
            </div>

        </div>
    )
}

const styles = {
    co: {
        minHeight: '100vh',
        padding: '200px 1rem',
        maxWidth: '1200px',
        marginInline: 'auto'
    },
    innerDiv: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '1rem',
    },
    h2: {

        fontSize: '1.5rem',
        marginBottom: '0.5rem'
    }
}