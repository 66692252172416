// src/utils/useLocalizedContent.js
// import { useParams } from "@reach/router";
//
import { useState, useEffect } from "react";

import { navigate } from "gatsby";

export const useLocalizedContent = (enContent, deContent) => {
    const { lang } = useParams() || {};

    return lang === 'de' ? deContent : enContent;
};



export const useLocal = () => {
    const [currentLocale, setCurrentLocale] = useState("en");

    useEffect(() => {
        if (typeof window !== "undefined") {
            const path = window.location.pathname;
            const segments = path.split("/").filter(Boolean);
            const locale = segments[0] === "de" ? "de" : "en";
            setCurrentLocale(locale);
        }

    }, [currentLocale]);


    const changeLocale = () => {
        const newLocale = currentLocale === "de" ? "en" : "de";
        setCurrentLocale(newLocale);

        if (typeof window !== "undefined") {
            if (newLocale === "de") {
                navigate(`/de${window.location.pathname}`);
            } else {
                navigate(window.location.pathname.replace("/de", ""));
            }
        }
    };

    return [currentLocale, changeLocale];
};