import React from 'react'
import { useLocal } from 'utils/useLocalContent'


const LangButton = () => {

    const [lang] = useLocal();
    const toggleLang = () => {
        window.location.pathname = lang === "de" ? window.location.pathname.replace("/de", "") : `/de${window.location.pathname}`
        // const scrollPosition = window.scrollY;
        window.location.pathname = lang === "de" ? window.location.pathname.replace("/de", "") : `/de${window.location.pathname}`;
        // window.scrollTo(0, scrollPosition);
        window.scrollTo(0, 0);
    }

    return (
        <button onClick={toggleLang} style={styles}>
            {lang === 'en' ? 'DE' : 'EN'}
        </button>
    )
}

export default LangButton

const styles = {

    padding: '0.5rem ',
    border: '1px solid rgba(255, 255, 255, 0.19)',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    backdropFilter: 'blur(5px)',
    borderRadius: '8px',
    color: '#ffffff',
}
