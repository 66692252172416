import React from 'react'

import * as styles from './custom.module.css'
import { useLocal } from 'utils/useLocalContent';

const PagesHeader = () => {

  const [lang] = useLocal();


  return (
    <section className=' valign' style={style.container}>
      <div style={style.overlay}>

      </div>
      <div style={style.innerCo} >
        <h1 className={`color-font fw-700 wow color-font${styles.title} `} >

          {lang === 'de' ? 'Über uns' : 'About us'}
        </h1>


      </div>

    </section>
    // <header className={`pages-header circle-bg valign ${styles}`}>
    //   <div className="container">

    //     <h1 className={`color-font fw-700 wow color-font${styles.title} `} >
    //       {lang === 'de' ? 'Über uns' : 'About us'}
    //     </h1>
    //     <div className="row justify-content-center">
    //       <div className="col-lg-10">
    //         <div className="cont mt-100 mb-50 text-center">

    //         </div>
    //       </div>

    //     </div>
    //   </div>
    //   <div className="half sub-bg">
    //     <div className="circle-color">
    //       <div className="gradient-circle"></div>
    //       <div className="gradient-circle two"></div>
    //     </div>
    //   </div>
    // </header>
  );
};

const style = {
  container: {
    // height: '12%',
    // minHeight: '200px',
    // backgroundColor: '#f4f4f4',
    maxHeight: '300px !important',
    padding: '250px 0',
    backgroundImage: 'url(https://n0xtunjqxyj91w6z.public.blob.vercel-storage.com/valvie-website/images/Interior%20Wall%203D%20Logo%20Mockup2%20%281%29-vvC9Xw6hBp4bvnqkh9RAFXmlODzkXM.jpg)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',


  },
  overlay: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: '1'
  },
  innerCo: {
    maxWidth: '1200px ',
    marginInline: 'auto',
    zIndex: '2',
    position: 'relative',
    width: '100%',
  }
}

export default PagesHeader;