import React from 'react'
import { useLocal } from 'utils/useLocalContent'



const content = {

    Valvie: 'UG (haftungsbeschränkt) & Co. KG',
    TaxNumber: '143/577/10090',
    Address: 'Harpprechtstr. 17, 80933 München',
    Phone: '+49 1577 4263587',
    Email: 'contact@valvie.de'
}

const contentDe = {
    Valvie: 'UG (haftungsbeschränkt) & Co. KG',
    Steuernummer: '143/577/10090',
    Adresse: 'Harpprechtstr. 17, 80933 München',
    Telefon: '+49 1577 4263587',
    Email: 'contact@valvie.de'
}


const index = () => {
    return (

        <Body />
    )
}

export default index

export const Head = () => {
    const [lang] = useLocal()
    return (
        <>
            <title>{lang === 'de' ? contentDe.title : content.title}</title>
        </>
    )
}




const Body = () => {

    const [lang] = useLocal()

    const c = lang === 'de' ? contentDe : content
    return (
        <div style={styles.co}>

            <div style={styles.innerDiv}>
                <h1>{lang === "de" ? "Impressum" : "Imprint"}</h1>


                {Object.entries(c).map(([key, value]) => (
                    <div key={key}>
                        <h2 style={styles.h2}>{key}</h2>
                        <p>{value}</p>
                    </div>
                ))}

            </div>

        </div>
    )
}

const styles = {
    co: {
        minHeight: '100vh',
        padding: '200px 1rem',
        maxWidth: '1200px',
        marginInline: 'auto'
    },
    innerDiv: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '1rem'
    },
    h2: {

        fontSize: '1.5rem',
        marginBottom: '0.5rem'
    }
}