import React from 'react'

import * as styles from '../custom.styles.module.css'


const index = () => {
    return (
        <>
            <section className={styles.pageContainer}>
                <div className={styles.pageInnerCo}>
                    <div className={styles.videoWrapper}>
                        <video
                            className={styles.fullscreenVideo}
                            src="https://n0xtunjqxyj91w6z.public.blob.vercel-storage.com/valvie-website/videos/promo2C-vGhi8GeAyqaKb0xWZyrJpsE2AEt1z6.mp4"
                            autoPlay
                            loop
                            muted
                            playsInline
                        />
                    </div>
                </div>

                <div className={styles.textContainer}>
                    <p className={styles.textShowcase}>
                        Together with your <span className="wow color-font">vision</span> and our <span className="wow color-font">expertise</span>, we elevate your brand's entire <span className="wow color-font">visual experience</span>. Whether through immersive 3D animations, dynamic 2D videos, or engaging live-action footage, we bring every idea to life with <span className="wow color-font">creativity</span>, <span className="wow color-font">precision</span>, and <span className="wow color-font">passion</span>. With our comprehensive skills and industry experience, we transform your concepts into <span className="wow color-font">captivating realities</span> that resonate with your audience.
                    </p>
                </div>
            </section>
        </>
    )
}

export default index